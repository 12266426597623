const colors = {
  primary: '#d02e77', // Color for buttons or links
  bg: 'white', // Background color
  grey: {
    dark: 'rgba(0, 0, 0, 0.9)',
    default: 'rgba(0, 0, 0, 0.7)',
    light: 'rgba(0, 0, 0, 0.5)',
    ultraLight: 'rgba(0, 0, 0, 0.25)',
  },
  white: 'white',
  featureTitle: '#0d2b73',
  clearBackground: '#0d2b73',
  clearText: '#2e3a41',
  buttonText: '#5af0b6'
}

const transitions = {
  normal: '0.5s',
}

const fontSize = {
  small: '0.8rem',
}

const fontFamily = {
  serif: `'Merriweather', Georgia, 'Times New Roman', Times, serif`,
  title: `'Montserrat'`,
  content: `'Raleway'`,
  sansSerif: `'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
}

const breakpoints = {
  desktop: '1600px',
  laptop: '1200px',
  tablet: '768px',
  phone: '600px',
}

const theme = {
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: '1000px',
  baseFontSize: '16px',
}

export default theme
