import React, { Component } from "react"
import Header from "./header"

import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import { GlobalStyle } from "../styles/global"

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <GlobalStyle/>
          <Header/>
          <div id='layout'>
            {this.props.children}
          </div>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

export default Layout
