import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Body from "../components/body"

export default () => {
  return (
    <Layout>
      <Seo/>
      <Body/>
    </Layout>
  )
}
