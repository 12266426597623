import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components";

const PaddedRow = styled(Row)`
  padding-top: 1rem;
`

const PaddedContainer = styled(Container)`
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-top: 75px;
  }
`

const FooterText = styled.p`
  font-size: ${props => props.theme.fontSize.small};
  font-style: italic;
`

const Spacer = () => {
  return (
    <span>{' · '}</span>
  )
}

const Body = () => {
  return (
    <section>
      <PaddedContainer>
        <PaddedRow>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
          <Col xs={12} md={8}>
            <h1 className={"text-center"}>
              DbAdmin.News
            </h1>
          </Col>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
        </PaddedRow>

        <PaddedRow>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
          <Col xs={12} md={8}>
            <h3 className={"text-center"}>
              A news letter on the technology behind
              Data Governance, Security and Privacy
            </h3>
          </Col>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
        </PaddedRow>
        <PaddedRow>
          <Col>
            <p>
              Are you
              <ul>
                <li>a data engineer ?</li>
                <li>data admin ?</li>
                <li>security engineer ?</li>
              </ul>

              Have you wondered how the largest tech companies
              <ul>
                <li>manage & govern their data ?</li>
                <li>secure data from external and internal threats ?</li>
                <li>comply with privacy regulations like HIPAA, GDPR & CCPA ?</li>
              </ul>
              Then this news letter is for you!
              DbAdmin.News keeps you up to date on the latest technology, best practices and lessons for data governance, privacy and  security.
            </p>
          </Col>
        </PaddedRow>
        <PaddedRow>
          <Col xs={12}>
            Want a preview ? Find the latest issues in <a href="https://dbadminnews.substack.com">https://dbadminnews.substack.com</a>
          </Col>
        </PaddedRow>
        <PaddedRow>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
          <Col xs={12} md={8} style={{maxWidth: '480px'}} className={"embed-responsive embed-responsive-21by9"}
               dangerouslySetInnerHTML={{
                 __html: `<iframe class="embed-responsive-item" src="https://dbadminnews.substack.com/embed"></iframe>`
               }}
          />
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
        </PaddedRow>

        <PaddedRow>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
          <Col xs={12} md={8}>
            <FooterText className={"text-center"}>
              Built using <a href={"https://gatsbyjs.org"}>Gatsby</a><Spacer />
              Managed by <a href={"https://substack.com"}>Substack</a><Spacer />
              Curated by <a href={"https://twitter.com/tokernhq"}>Tokern</a>
            </FooterText>
          </Col>
          <Col md={2} className={"d-none d-md-block"}><span></span></Col>
        </PaddedRow>
     </PaddedContainer>
    </section>
  )
}

export default Body;
