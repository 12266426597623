import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import brandLogo from "../images/logo.png"
import { Link } from "gatsby"

const HeaderEl = styled.header`
  padding:20px 0 30px 0;
  transition:all 200ms ease-in-out;
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:56px;
    padding:10px 0;
    background: #fff;
    border-bottom: solid 1px #f1f1f1;z-index: 5;
  }
`

const Logo = styled(Link)`
  max-width: 200px;
  display: block
`

const ExtLink = styled.a`
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    font-size: ${props => props.theme.fontSize.small};
    margin-left: 3px;
  }
`
const Header = () => {
  return (
    <HeaderEl id="header">
      <Container fluid={true}>
        <Row className="align-items-center no-gutters">
          <Col xs={8}>
            <Logo to='/'>
              <img src={`${brandLogo}`} className="img-fluid" alt="DbAdmin.News"/>
            </Logo>
          </Col>
          <Col xs={2}>
            <ExtLink href={"https://dbadminnews.substack.com/"}>Latest</ExtLink>
          </Col>
          <Col xs={2}>
            <ExtLink href={"https://dbadminnews.substack.com/archive"}>Archives</ExtLink>
          </Col>
        </Row>
      </Container>
    </HeaderEl>
  )
}

export default Header
