import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

import favicon from "../images/favicon.ico"
import imageUrl from "../images/logo.png"

const Seo = ({ props }) => {
  const data = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
          twitter
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const siteUrl = data.site.siteMetadata.siteUrl
  const twitterAuthor = data.site.siteMetadata.twitter
  const ogType = "website"
  const prefix = "og: http://ogp.me/" + ogType + "/ns#"

  return (
    <Helmet defer={false}
            defaultTitle={siteTitle}
            titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang="en" prefix={prefix}/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="docsearch:version" content="2.0" />

      <link rel="shortcut icon" href={`${favicon}`} type="image/x-icon"/>
      <link rel="icon" href={`${favicon}`} type="image/x-icon"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no" />

      <meta property="description" content={siteDescription} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content={ogType} />
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content={siteTitle} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:description" content={siteDescription} />
      <meta
        name="twitter:creator"
        content={twitterAuthor}
      />

      <link rel="stylesheet" type="text/css" media="screen" href="https://fonts.googleapis.com/css?family=Montserrat:600,700|Raleway:500,700&display=swap"/>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
            integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous"
      />
    </Helmet>
  )
}

export default Seo;
